import useKey from "@/hooks/use-key";
import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";

const Carousel = ({ className }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const coverImages: any = useKey(
    "astrolearn_main_website_home_page_carousel",
    {
      json: true,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % coverImages?.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [coverImages]);

  return (
    <div className="md:p-4 flex flex-col items-center justify-center gap-2 bg-white bg-opacity-0 z-10 relative max-md:mt-4 rounded-lg">
      <div
        className={`relative max-md:w-full md:max-w-[350px] lg:max-w-[450px] xl:max-w-[550px] mx-auto overflow-hidden bg-transparent rounded-lg ${className}`}
      >
        <div
          className="flex transition-transform duration-700 ease-in-out rounded-lg"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {coverImages &&
            Object.values(coverImages).map((image: any, index: any) => (
              <Link
                key={index}
                href={image.url}
                className="min-w-full flex justify-center items-center rounded-lg"
              >
                <Image
                  width={619}
                  height={348}
                  src={image.image}
                  alt="thumbnail"
                  className="max-md:rounded-[9.8px] md:rounded-lg object-cover shadow-lg" // Here we set the width to 2/3 of the container width
                />
              </Link>
            ))}
        </div>
      </div>
      <div className="flex items-center justify-center gap-1">
        {coverImages &&
          [...Array(Object.keys(coverImages).length)].map((_, index) => {
            return (
              <div
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`${
                  currentIndex == index
                    ? "w-6 h-2.5"
                    : "w-2.5 h-2.5 bg-opacity-40"
                } bg-[#BA9A63] transition-all duration-300 rounded-full hover:cursor-pointer z-10 md:mt-1`}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Carousel;
