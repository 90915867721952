import Image from "next/image";
import React from "react";
import Marquee from "react-fast-marquee";

export default function MobileCarousel({
  images,
  className,
}: {
  images: string[];
  className?: string;
}) {
  return (
    <div
      className={`flex justify-center items-center max-sm:gap-2 gap-[24px] md:gap-[40px] ${className} -mb-[10px]`}
    >
      <Marquee pauseOnHover className="[--duration:20s]">
        {images.map((image) => (
          <Image
            key={image}
            src={image}
            width={328}
            height={206}
            alt="what our students say"
            className="ml-4 object-contain"
          />
        ))}
      </Marquee>
    </div>
  );
}
