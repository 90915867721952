import React, { useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export default function FaqExpandableTile({
  FaqQuestion,
  FaqAnswer,
  isOpen,
  className,
}: {
  FaqQuestion: string;
  FaqAnswer: string;
  isOpen: boolean;
  className?: string;
}) {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <div
        className={` rounded-[8px] ${
          isOpen ? "bg-white !rounded-b-[0px]" : "bg-[#E6DFD3]"
        } relative p-[20px] md:px-[36px] flex flex-row mt-5 justify-between ${className}`}
      >
        <p
          className={`text-black mr-2 max-md:text-[15px] md:px-[24px] text-[18px] pr-7 font-bold font-lato`}
        >
          {FaqQuestion}
        </p>
        <ChevronDownIcon
          className={`!px-0 absolute right-4 cursor-pointer text-[#041C33] ${
            isOpen
              ? "transition-all duration-300 rotate-180"
              : "transition-all duration-300 rotate-0"
          }`}
          width={24}
        ></ChevronDownIcon>
      </div>
      <div
        ref={contentRef}
        className="overflow-hidden transition-all duration-300 ease-in-out"
        style={{
          maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : "0px",
          opacity: isOpen ? 1 : 0,
        }}
      >
        <div className="bg-white text-[#00000099] font-semibold text-start text-[15px] md:font-[400] md:text-[18px] md:px-[36px] rounded-b-[8px] p-[14px] md:pb-[14px] md:pt-[0px] ">
          {FaqAnswer}
        </div>
      </div>
    </div>
  );
}
