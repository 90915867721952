import { ClockIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import React from "react";
import Image from "next/image";
import { motion } from "framer-motion";
import { CalendarIcon, StarIcon } from "@heroicons/react/24/solid";
import { Button } from "./ui/button";
import mixpanel from "@/utils/mixpanel";

function activeSlot(slots: any) {
  for (let i = 0; i < slots?.length; i++) {
    if (
      slots[i].active === true &&
      slots[i].startDateTime >= dayjs().toISOString()
    ) {
      return slots[i];
    }
  }
}

export default function MasterclassCard({ masterclass, ...props }: any) {
  const router = useRouter();

  const achievements = masterclass.bootcamp?.teacher?.achievements;
  let averageRating = null;
  let totalRatings = null;
  if (achievements) {
    const [avgRating, totalRating] = achievements.split(";");
    averageRating = avgRating ? parseFloat(avgRating) : null;
    totalRatings = totalRating ? parseInt(totalRating, 10) : null;
  }
  const starArray = [1, 2, 3, 4, 5];
  const validAverageRating =
    averageRating !== null && !isNaN(averageRating) ? averageRating : 5;

  return (
    <motion.div
      {...props}
      className="h-full min-w-[350px] md:min-w-[370px] flex md:max-w-[500px] w-full mx-auto bg-white flex-col gap-2 rounded-lg shadow-[0_0px_7.596px_0px_rgba(190,190,190,0.4)] p-5 max-xl:p-3 font-lato cursor-pointer"
      onClick={() => {
        router.push(`/masterclasses/${masterclass?.slug}`);
        mixpanel.track("view_details_masterclass", {
          masterclass: masterclass?.title,
        });
      }}
      key={masterclass?.slug}
    >
      <Image
        width={366}
        height={206}
        src={masterclass.bootcamp.coverImage || "/common/fallback-cover.webp"}
        alt="thumbnail"
        className="rounded-md w-full object-cover shadow-[0_0.9px_3.6px_0px_rgba(0,0,0,0.06)] max-h-[230px]"
      />
      <div className="flex justify-between items-center w-full max-xl:text-xs pb-2 border-b-2 border-[#ACACAC7A] border-dotted font-semibold">
        <div className="flex items-center gap-1">
          <CalendarIcon className="w-4 h-4" />
          <p className="text-sm">
            {dayjs(activeSlot(masterclass?.slots)?.startDateTime)?.format(
              "DD MMM (dddd)"
            )}
          </p>
        </div>
        <div className="flex items-center gap-1">
          <ClockIcon className="w-4 h-4 text-[#0A2133]" />
          <p className="text-sm">
            {dayjs(activeSlot(masterclass?.slots)?.startDateTime).format(
              "hh:mm A"
            )}
          </p>
        </div>
      </div>

      <div className="flex justify-between items-center w-full max-xl:text-xs font-semibold">
        <div className="flex gap-1 bg-[#9A70261A] rounded-full px-3 py-0.5">
          {masterclass?.categories?.length > 0 ? (
            masterclass?.categories?.map((category: any, index: number) => (
              <React.Fragment key={category?.id}>
                <p className="text-sm text-[#9A7026] text-center">
                  {category.name}
                </p>
                {masterclass?.categories?.length > 1 &&
                  index !== masterclass?.categories?.length - 1 && (
                    <p className="text-sm text-[#9A7026]"> | </p>
                  )}
              </React.Fragment>
            ))
          ) : (
            <p className="text-sm text-[#9A7026]">Spirituality</p>
          )}
        </div>
        <div className="flex justify-center items-center gap-1">
          <div className="flex items-center justify-center">
            {validAverageRating !== null
              ? starArray.map((stars, index) => {
                  if (stars <= validAverageRating) {
                    return (
                      <StarIcon
                        key={index}
                        className="w-4 h-4 text-[#9A7026]"
                      />
                    );
                  } else {
                    return (
                      <StarIcon
                        key={index}
                        className="w-4 h-4 text-[#9A702666]"
                      />
                    );
                  }
                })
              : starArray.map((_, index) => (
                  <StarIcon key={index} className="w-4 h-4 text-[#9A7026]" />
                ))}
          </div>
          <p className="text-sm">
            {validAverageRating} (
            {totalRatings !== null
              ? totalRatings >= 1000
                ? `${Math.floor(totalRatings / 1000)}k+`
                : totalRatings
              : "10k+"}
            )
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-between h-full">
        <h4 className="font-semibold md:text-lg w-[300px] multiline-ellipsis">
          {masterclass?.title}
        </h4>
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-1 items-center relative">
            <p className="text-[#9A7026] font-semibold text-lg">Free</p>
            <p className="flex flex-col justify-center text-[#606060] text-sm line-through">
              ₹499
            </p>
          </div>
          <Button
            size={"sm"}
            className="rounded font-bold px-6 py-1.5"
            onClick={() => {
              router.push(`/masterclasses/${masterclass?.slug}`);
            }}
          >
            Register Now
          </Button>
        </div>
      </div>
    </motion.div>
  );
}
