import Image from "next/image";
import React from "react";

export default function NewTagCard({
  headerText,
  img,
  onClick,
}: {
  headerText: string;
  img: string;
  onClick: () => void;
}) {
  return (
    <div
      className="relative py-6 px-4 pr-12 md:px-7 cursor-pointer md:w-full md:py-10 bg-white rounded-xl overflow-hidden w-full"
      onClick={onClick}
    >
      <p className="font-semibold text-base md:text-xl xl:text-2xl font-lato text-[#00163A]">
        {headerText}
      </p>

      <Image
        width={72}
        height={72}
        src={img}
        className="absolute bottom-0 right-1 opacity-75 w-14 h-14 xl:w-20 xl:h-20"
        alt="decorative"
      />
    </div>
  );
}
