import mixpanel from "@/utils/mixpanel";
import { useRouter } from "next/router";
import React from "react";

export default function BlogPhotoTile({
  imgSrc,
  title,
  minutesRead,
  slug,
}: {
  imgSrc: string;
  title: string;
  minutesRead: number;
  slug: string;
}) {
  const router = useRouter();
  return (
    <div
      className="lg:relative text-white rounded-xl lg:min-w-[250px] max-lg:min-w-[149.77px] hover:cursor-pointer overflow-hidden"
      onClick={() => {
        router.push(`/blogs/${slug}`);
        mixpanel.track("blog_card_click", {
          blog: title,
        });
      }}
    >
      <img
        src={imgSrc || ""}
        className="max-lg:w-[149.77px] max-lg:h-[157.54px] w-[250px] h-[250px] object-cover rounded-xl"
      />
      <div className="lg:absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
      <div className="lg:absolute text-start lg:bottom-2 lg:left-2 flex flex-col justify-start z-10">
        <p className="text-start mt-1 font-lato text-xl max-lg:text-sm font-bold max-lg:text-[#1A1A1A] lg:text-white">
          {title}
        </p>
        <p className="text-xl max-lg:text-xs max-lg:text-[#8D97B0] lg:text-white text-start font-lato">
          {minutesRead} min read
        </p>
      </div>
    </div>
  );
}
