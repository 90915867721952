import React from "react";

export default function PromiseCard({
  headerImage,
  headerText,
  body,
  className,
}: {
  headerImage: string;
  headerText: string;
  body: string;
  className?: string;
}) {
  return (
    <div
      className={`bg-white flex flex-col items-center justify-between text-center p-6 rounded-lg ${className}`}
    >
      <div className="bg-[#ce9636]">
        <img
          src={headerImage}
          alt=""
          className="md:w-[100px] w-[50px] mix-blend-luminosity scale-105"
        />
      </div>
      <p className="text-xl text-[#00163A] font-extrabold font-lato md:text-2xl">
        {headerText}
      </p>
      <p className="text-sm font-lato text-[#5B5B5B] md:text-base max-w-[500px] md:mt-3 md:min-h-[50px]">
        {body}
      </p>
    </div>
  );
}
