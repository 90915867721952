import { useRouter } from "next/router";
import Link from "next/link";
import { cn } from "@/lib/utils";

const Breadcrumb = ({
  classNames,
  textColor,
}: {
  classNames?: string;
  textColor?: string;
}) => {
  const router = useRouter();
  const pathSegments = router.asPath.split("/").filter((segment) => segment);

  return (
    <nav className={classNames}>
      <ul className="flex space-x-2">
        <li key="home">
          <Link
            href="/"
            className={cn(textColor ? textColor : "text-[#6E7485]")}
          >
            Home
          </Link>
          <span className={cn(textColor ? textColor : "text-[#6E7485]")}>
            {" "}
            &gt;{" "}
          </span>
        </li>
        {pathSegments.map((segment, index) => {
          const href = "/" + pathSegments.slice(0, index + 1).join("/");

          return (
            <li key={href}>
              <Link
                href={href}
                className={cn(textColor ? textColor : "text-[#6E7485]")}
              >
                {segment.includes("-")
                  ? (() => {
                      const words = segment.split("-");
                      if (
                        words.length > 0 &&
                        words[words.length - 1].length === 9 // to remove mx id present in slug
                      ) {
                        words.pop();
                      }
                      return words
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ");
                    })()
                  : segment.charAt(0).toUpperCase() + segment.slice(1)}
              </Link>
              {index < pathSegments.length - 1 && (
                <span className={cn(textColor ? textColor : "text-[#6E7485]")}>
                  {" "}
                  &gt;{" "}
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
