import { ClockIcon, EyeIcon } from "@heroicons/react/24/outline";

export default function BlogTiles({
  blog,
  minutesRead,
}: {
  blog: any;
  minutesRead: number;
}) {
  return (
    <div className="rounded-2xl font-lato bg-white flex">
      <div
        className="min-w-40 h-40 lg:min-w-44 lg:h-56 xl:min-w-64 xl:h-72 bg-cover bg-center rounded-l-xl"
        style={{ backgroundImage: `url(${blog?.coverImage})` }}
      />
      <div className="flex flex-col justify-center gap-1 sm:gap-4 w-full p-4">
        <p className="text-[#1A1A1A] text-base md:text-lg xl:text-2xl font-bold line-clamp-2 leading-tight">
          {blog?.title}
        </p>
        <p className="text-[#3E3E3ECC] font-medium line-clamp-3 text-sm lg:text-lg leading-tight">
          {blog?.shortDescription}
        </p>
        <div className="flex items-center justify-between w-full text-xs sm:text-sm mt-1">
          <div className="flex items-center justify-center gap-1 text-[#9A7026]">
            <ClockIcon className="w-3 md:w-5" />
            <p>{minutesRead} min read</p>
          </div>
          <div className="flex items-center justify-center gap-1 text-[#3E3E3ECC]">
            <EyeIcon className="w-3 md:w-5" />
            <p>{blog?.views} views</p>
          </div>
        </div>
      </div>
    </div>
  );
}
